@import "@milestone-sys/web-design-system/scss/_variables.scss";

.no-matched-company-page {
  margin: 2rem 2rem 1rem 2rem;
  .breaker {
    height: 1px;
    width: 50px;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  &__description {
    margin-bottom: 2rem;
  }

  &__data-section {
    margin-bottom: 2rem;
  }

  &__data-item {
    display: flex;
    align-items: center;
    gap: 5px;
    p {
      margin: 0px;
    }
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: flex-start;
    margin-bottom: 2rem;
  }
}

@media (min-width: 768px) {
  .no-matched-company-page {
    margin: 2rem 5rem 1rem 5rem;
  }
}

@media (min-width: 992px) {
  .no-matched-company-page {
    margin: 2rem 10rem 1rem 10rem;
  }
}
