@import "@milestone-sys/web-design-system/scss/_variables.scss";

.user-information-page {
  margin: 0 2rem;
  .breaker {
    height: 1px;
    width: 50px;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  &__description {
    margin-bottom: 2rem;
  }

  &__data-item {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 5px;
    p {
      margin: 0px;
    }
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: flex-start;
  }

  .msds-telephone-field {
    display: flex;
    flex-wrap: wrap;

    &>div {
        padding: 0;
    }

    .msds-select-input {
        margin-right: 21px;
        margin-bottom: 21px;
    }
  }

  .mt-30 {
    margin-top: 30px;
  }
}

@media (min-width: 768px) {
  .user-information-page {
    margin: 0 5rem;
  }
}

@media (min-width: 992px) {
  .user-information-page {
    margin: 0 10rem;
  }
}
