.user-portal {
    &__licence-claim-buttons {
        gap: 24px;
        
        .msds-btn{
            margin-top: unset;
        }
    }
    
    &__claim-license-success {
        width: 114px;
        height: auto;
    }
}