
.overview-submission-step {
        &__no-admin-error-icon{
                width: 114px;
                height: auto;
        };

        &__container {
                margin-top: 20px;
        }
}



