html,
body {
    margin: 0;
    font-family: "Open Sans", sans-serif;
    height: 100%;
}

.body-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.user-portal {
    .mt-20{
        margin-top: 20px;
    }
    flex: 1;
    display: flex;

    &__reset-password-form,
    &__reset-password-form-success,
    &__create-login-form,
    &__create-login-form--success,
    &__create-new-password-form,
    &__create-new-password-form-success,
    &__user-account-activation,
    &__user-temporary-profile-form,
    &__user-temporary-profile-form-success,
    &__edit-user-details-subscription,
    &__edit-user-details-form,
    &__user-updated-email-confirmation {
        .msds-btn {
            margin-bottom: 48px;
        }
    }

    &__split-container {
        height: 100%;

        &-left-side-container {
            flex: 0;
        }

        &-right-side-container {
            flex: 1;
        }
    }

    &__description-text {
        padding-bottom: 24px;
    }

    &__temporary-profile-marketing-text{
        margin:0;
    }

    form {                
        .msds-input {
            margin-bottom: 21px;
        }

        .msds-telephone-field {
            display: flex;
            flex-wrap: wrap;

            &>div {
                padding: 0;
            }

            .msds-select-input {
                margin-right: 21px;
                margin-bottom: 21px;
            }
        } 

        .msds-select-input__selected-option {
            color: #303436;
        }
    }
}

@media (min-width: 768px) {
    .user-portal {
        position: relative;

        &__reset-password-form,
        &__reset-password-form-success,
        &__create-login-form--success,
        &__create-new-password-form,
        &__create-new-password-form-success,
        &__user-account-activation,
        &__user-temporary-profile-form-success,
        &__send-to-milestone-support-confirmation,
        &__user-updated-email-confirmation {
            height: auto;
            max-height: 720px;
        }
        &__user-updated-email-confirmation {
            height: 500px;
        }
    }
}

@media (min-width: 992px) {
    .user-portal {
        position: relative;

        &__split-container {
            height: 100%;
            // display: flex;
            // flex-wrap: wrap;

            &-left-side-container,
            &-right-side-container {
                flex: unset;
            }
        }
    }
}