@import "@milestone-sys/web-design-system/scss/_variables.scss";

.msds-checkboxes-field {
  &__wrapper {
    padding-bottom: 0.75rem;
    border: 1px solid transparent;
  }

  &__header {
    padding: 1.5rem 1.25rem 0;
    font-size: 1.125rem;
    line-height: 1.625rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    padding: 0 1.25rem;

    .msds-checkbox, .msds-popover {
      flex: 50%;
      display: inline-flex;
    }

    .msds-checkbox:nth-child(n) {
      padding-right: 10px;
    }
    .msds-checkbox:nth-child(2n) {
      padding-left: 10px;
    }

    .msds-input__info-message {
      flex: 100%;
    }
  }

  &--error {
    .msds-checkboxes-field__wrapper {
      border-color: $danger-red-1;
    }

    .msds-input__info-message {
      display: block;
      color: $danger-red-1;
    }
  }

  .msds-input__info-message {
    display: none;
  }
}
