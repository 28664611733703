@import "@milestone-sys/web-design-system/scss/_variables.scss";

.company-list {
  margin-top: 2rem;
  
  .breaker {
    height: 1px;
    width: 50px;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  &__count {
    margin: 2rem 0;
  }

  &__selections {
    overflow-y: scroll;
    &--long {
      height: 400px;
    }
  }

  &__item {
    margin: 1rem 0;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 24px;
    background-color: $gray-2;
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: flex-start;
    margin-bottom: 2rem;
  }
}
