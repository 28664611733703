@import "@milestone-sys/web-design-system/scss/_variables.scss";

.msds-table {
    overflow-x: auto;
    display: block;
    width: 100%;
    &__header--expander{
        width: 3%;
    }
    table {
        min-width: 100%;
        overflow-x: scroll;
        border-collapse:collapse;
        border-left: 1px solid #f5f6f7;
        border-right: 1px solid #f5f6f7;            
    }    
    &__table-style {
        table-layout: fixed;
    }

    tbody {
        .msds-table__body-row {
            background-color: $white;
            border-bottom: 1px solid $gray-3;   
            
            &:first-child {
                border-top: 1px solid $gray-3;   
            }

            &:hover {
                background-color: $gray-3;
                box-shadow: inset 0 -.0625rem 0 0 hsla(0, 0%, 100%, 0);
            }
        }
    }
    
    &__body-row-data {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    &__expandable-row {
        &-icon {
            width: 0.75rem;
            height: 0.75rem;
            fill: #0099da;
            &--expanded {
                transform: rotate(90deg);
            }
        }        
    }
    &__body-row-clickable {
        cursor: pointer;
    }
    &__body-row-data-exapanded {
        background: #fff;
        padding: 2.375rem 1.0625rem;
    }
    &__pagination {    
        text-align: center;                         
        margin: 20px 0 40px;
        .msds-btn {
            min-width: 50px;
            padding: 5px 10px;
            margin: 0 2px;
            
            &:first-child {
                margin-left: auto;
            }
        }
        &-indicator {
            margin: 20px 0;
            .msds-input__text-input {
                display: inline-block;
                margin-left: 5px;                
                width: 80px;
                height: 36px;
                padding: 0 0 0 10px;
            }
        }
        &-show-row-numbers {
            position: relative;
            display: inline-block;
            margin-left: auto;
            .msds-select-input__select {
                height: unset;
                padding: 6px 50px 8px 20px;        
            }            
        }
    }    
}

@media (min-width: 768px) {
    .msds-table {
        &__pagination {  
            display: flex;       
            text-align: left;
            &-indicator {
                margin: 0 10px;
            }            
        }
    }
}