@import "@milestone-sys/web-design-system/scss/_variables.scss";

.footer-column {
    $self: &;
    margin: 0;
    padding-top: 72px;
    padding-bottom: 48px;
    background: $white;        

    &__breaker-wrapper {
        padding-left: 10px;
        padding-right: 10px;
    }

    &__breaker-wrapper-breaker {
        margin-bottom: 40px;
        height: 1px;
        background: $gray-5;
    }

    &__text {
        font-size: 12px;
        color: $gray-10;
        line-height: 18px;
    }

    &__milestone-logo {
        background: transparent url(../Header/milestone-logo.svg) no-repeat 0 0;
        background-size: 114px 24px;
        width: 114px;
        height: 24px;
        margin: 5px 0 20px;
    }

    &__links-list {
        list-style: none;
        margin-left: 0;
        padding: 0;

        &--with-heading {
            .footer-column__links-list-item:nth-child(1) {
                font-weight: 600;
                text-transform: uppercase;
                font-size: 14px;
                letter-spacing: 0.3px;
                margin-bottom: 17px;
                line-height: 20px;

                .footer-column__links-list-item-link {
                    color: $dark-blue;
                }
            }
        }
    }

    &__links-list-item {
        padding: 0;
        margin: 0;
        color: white;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 2px;

        &--no-opacity {
            opacity: 1;
        }

        &--align-row {           
            div:first-child {
                display: flex;
                flex-wrap: wrap;
            }
        }
    }

    #{ $self }__links-list-item-link {
        color: $gray-10;
        text-decoration: none;

        &:hover {
            cursor: pointer;
            text-decoration: underline;            
        }
    }

    &__links-list-item-social-media {
        width: 120px;
    }
}

@media (min-width: 576px) {
    .footer-column {
        padding-top: 48px;

        &__breaker-wrapper {
            padding-left: 8px;
            padding-right: 8px;
        }

        &__text {
            padding-right: 10px;
        }

        &__milestone-logo {
            margin-top: 0;
        }

        &__links-list {
            padding-left: 20px;
            margin-right: 0;
            padding-left: 0;            
        }

        &__links-list-item {               
            &--align-row {
                width: 60%;    
            }
        }
    }
}

@media (orientation: portrait) and (max-width: 479px) {
	.navigation {
		.header-links {
			height: auto !important;
		}
	}
}
