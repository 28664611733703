.user-portal {
    &__signup-form {
        .telephone-row-container {
            display: flex;
            flex-wrap: wrap;

            & > div {
                padding: 0;
            }

            .msds-select-input {
                margin-right: 21px;
                margin-bottom: 21px;
            }
        }

        .msds-checkbox > label > p {
            margin: 0px 0px 16px 0px;
        }
    }
}

.field-description-container {
    top:-10px;
    display:flex;
}

.field-definition-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    display: inline-block;
    padding-bottom: 20px;
}

.email-text {
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: #7C8B95;
    padding-bottom: 20px;
    margin-top:-10px;
}

.popoverTitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #303436;
    margin:20px 0 24px 0;
}

.popoverDefinition {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
}

.question-icon-container {
    background-color: #CED3D7;
    width: 20px;
    height: 20px;
    display:flex;
    border-radius: 50%;
    align-items: center;
    margin-right: 7px;
}

.msds-select-input__question {
    height: 0.7rem;
    // width: 0.7rem;
    fill: #000000;
    pointer-events: none;
    text-overflow: ellipsis;
    overflow: hidden;

}
// .msds-select-input__selected-option {
//     color: #303436 !important;
// }
