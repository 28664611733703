@import "@milestone-sys/web-design-system/scss/_variables.scss";

.user-portal {
    &__signup-form {
        .telephone-row-container {
            display: flex;
            flex-wrap: wrap;

            & > div {
                padding: 0;
            }

            .msds-select-input {
                margin-right: 21px;
                margin-bottom: 21px;
            }
        }

        .msds-checkbox > label > p {
            margin: 0px 0px 16px 0px;
        }
    }
}
