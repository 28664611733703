.user-portal {
  &__reset-password-form {
    .reset-password-form__create-login-link {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 48px;
    }
  }
}
