
@import "@milestone-sys/web-design-system/scss/_variables.scss";
  .popover {
    position: absolute;
    top: 100%;  // Positions popover directly below the button
    left: 100%;  // Centers popover horizontally with respect to the button
    transform: translateX(-115%);  // Adjusts it back by 50% of its own width to truly center it
    width: 300px;
    padding: 12px 30px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background:white;
    
    z-index: 10;

    &__arrow {
        height: 1.5625rem;
        width: 37px;
        position: absolute;
        bottom: 100%;
        right: 0%;
        transform: translateX(-50%);
        overflow: hidden;
        color: #fff;
    
        &:after {
            content: "";
            position: absolute;
            width: 1.25rem;
            height: 1.25rem;
            background: currentColor;
            box-shadow: 0 0 0.375rem -0.0625rem rgba(0, 0, 0, 0.3);
            top: 100%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%) rotate(45deg);
        }
    }

    &__list {
      list-style-type: none;
      display:flex;
      flex-direction: column;
      padding: 0;
      margin: 0;
    }
    
    a {
      color: $gray-10 !important; //important to overwrite msds
      text-decoration: none !important; //important to overwrite msds
      &:visited {
        text-decoration: none;
      }
      &:hover {
        color:$clear-blue !important;
      }
    }
    &__list-item {
      margin: 12px 0;
      cursor: pointer;
      font-size: 12px;
      text-decoration: none !important ; //important to overwrite msds
      color: $gray-10 !important; //important to overwrite msds
      a:visited {
        text-decoration: none;
        color: $gray-10;
      }
      &:hover {
        color:$clear-blue !important;
      }
    }

    @media (max-width: 768px) {
      position: fixed;  
      top: 50px;
      left: 50%;
      width: 50%;
      height: 80vh;
      padding: 16px 20px;
      box-shadow: none;
      border-radius: 0;
      z-index: 100000;
      transform: none;
      &__arrow {
        display: none; 
      }
    }

    @media (max-width: 576px) {
      position: fixed;
      top: 50px;
      left: 0;
      width: 100%;
      height: 80vh;
      padding: 16px 20px;
      box-shadow: none;
      border-radius: 0;
      z-index: 100000;
      transform: none;
      &__arrow {
        display: none;
      }
    }

  }
  
