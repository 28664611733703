@import "@milestone-sys/web-design-system/scss/_variables.scss";

.company-list {
  .breaker {
    height: 1px;
    width: 50px;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  &__count {
    margin: 2rem 0;
  }

  &__selections {
    overflow-y: scroll;
    position: relative;
    
    &--long {
      height: 500px;
    }
  }

  &__item {
    margin: 1rem 0;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 24px;
    background-color: $white;
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: flex-start;
  }

  &__not-on-list-button {
    color: $alt-blue-1;
    border: none;
    background: none;
    font-weight: medium;
    &:hover {
      cursor: pointer;
    }
  }
}
