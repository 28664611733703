.dialog {
    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.5);
        display: flex;
        justify-content: center;
        align-items: center;

        &--hidden {
            display: none;
        }
    }

    &__container {
        padding: 0 24px;
        padding-bottom: 16px;
        background-color: white;
        border-radius: 1rem;
        width: 700px;
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__header {
        display: flex;
        justify-content: space-between;
    }

    &__content {
        overflow-y: scroll;
    }

    &__actions {
        display: flex;
        justify-content: flex-end;
        gap: 0.8rem;
    }
}