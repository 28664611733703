@import "@milestone-sys/web-design-system/scss/_variables.scss";

// Main Navigation z-index definition 
// [3000 - 3990]
$zindex-main-nav-1: 3000;
$zindex-main-nav-2: 3010;
$zindex-main-nav-3: 3030;
$zindex-main-nav-4: 3040;
$zindex-main-nav-5: 3050;

.header-section-sticky {
    position: sticky;
    top: 0;
    z-index: $zindex-main-nav-4;
}

.header-section-block {
    background: $white;

    &__container {
        position: relative;
        width: 100%;
        z-index: $zindex-main-nav-4;
        display: flex;
        align-items: center;
    }

    &__milestone-logo {
        background: transparent url(./milestone-logo.svg) no-repeat 0 0;
        background-size: 114px 24px;
        width: 114px;
        height: 24px;
        margin: 13px 0 13px 10px;
    }

    &__navigation {
        width: 100%;
        display: none;
        position: fixed;
        top: 0;
        right: 0;
        border-bottom: 0;
        height: 100%;
        background: rgba(0,0,0,.7);
        z-index: $zindex-main-nav-1;
        overflow-y: auto;

        &--show {
            display: inline-flex;

            .header-section-block__navigation-menus > .header-section-block__navigation-menu-container {
                display: inline-flex;
            }
        }
    }

    &__navigation-menus {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0;
        position: absolute;
        top: 0;
        right: 0;
        z-index: $zindex-main-nav-3;
        overflow-y: auto !important;
        background: white;
        height: 100%;

        &-button-close-container {
            background: $white;
            padding: 14px 16px 11px;
            text-align: right;

            .header-section-block__navigation-menus-button-close {
                cursor: pointer;

                > svg {
                    fill: $gray-10;
                    width: 26px;
                    height: 26px;
                }

                &:hover {
                    > svg {
                        fill: $clear-blue;
                    }
                }
            }
        }
    }

    &__navigation-menu-container {
        position: relative;
        display: none;
        flex-direction: column;
        width: 100%;
        background: $white;

        &:first-child {
            > .header-section-block__navigation-menu {
                border-top: 0 none;
            }
        }

        &__navigation-menu-container--active {
            display: inline-flex;
        }
    }

    &__navigation-menu {
        display: inline-flex;
        align-items: center;
        text-decoration: none;
        flex-wrap: wrap;
        cursor: pointer;

        &--level1 {
            .header-section-block__navigation-menu-label {
                color: $black;
                font-size: 12px;
                font-weight: 600;
                line-height: 18px;
                text-transform: capitalize;
                flex: 1;
                text-decoration: none;
            }

            &:focus > .header-section-block__navigation-menu-container {
                display: flex;
            }
            
            &-parent {
                .header-section-block__navigation-menu-label {
                    color: $clear-blue;
                }
            }
        }
    }

    &__navigation-menu-label {
        user-select: none;
        padding: 17px 10px 17px 20px;
    }

    &__links {
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: 2px;

        .msds-btn {
            display: none;
        }
    }

    &__links-burger-menu-link {
        cursor: pointer;
        user-select: none;
        border: 0 none;
        background: transparent;

        .ms-icon {
            cursor: pointer;

            > svg {
                fill: $gray-8;
                width: 22.5px;
                height: 22.5px;
            }
        }

        &.close {
            .ms-icon {
                > svg {
                    fill: $clear-blue;
                    width: 22.5px;
                    height: 22.5px;
                }
            }
        }        
    }

    &__links-burger-menu-link {
        margin-right: 17px;
        padding: 0;

        &:hover {
            .ms-icon > svg {
                fill: $clear-blue;
            }
        }
    }      
}

//@include media-breakpoint-up(sm) {
@media (min-width: 576px) {
    .header-section-block {

        &__container {
            width: 100%;
            background: $white none repeat scroll 0 0;
            z-index: $zindex-main-nav-4;
        }

        &__milestone-logo {
            margin: 23px 0 23px 20px;
            background-size: 140px 29px;
            width: 140px;
            min-width: 140px;
            height: 29px;
        }

        &__navigation-menus {
            width: 50%;
        }        

        &__burger-links {
            padding: 0;

            &-header-button {
                .msds-btn {
                    display: none;
                }
            }
        }

        &__links {
            .msds-btn {
                display: inline-flex;
                margin: 0 20px 0 0;
            }
        }

        &__links {
            &-header-button {
                .msds-btn {
                    display: inline-flex;
                    margin: 0 20px 0 0;
                }
            }
        }    
    }

    .header-section-block__login-button:hover + .popover .popover__list-item {
        color: black; // Force the list item color to stay black
      }
}

@media (max-width: 768px) {
    .header-section-block {
        &__login-button {
            margin-left: 20px;
        }
        &__login-button:first-child  {
            display: none;
        }
    }
}
@media (min-width: 768px) {
    .header-section-block {   
        &__navigation {
            width: unset;
            height: unset;
            display: flex;
            position: unset;
            border: 0 none;
            background: transparent;
            overflow: unset;
            margin-left: 37px;
        }

        &__navigation-menus {
            width: unset;
            flex-direction: row;
            position: unset;

            & > .header-section-block__navigation-menu-container {
                display: flex;

                .header-section-block__navigation-menu-container {
                    display: none;
                }
            }
        }

        &__navigation-menu-container {
            display: none;
            margin-left: 25px;

            &:nth-child(2) {
                margin-left: 0;
            }
        }

        &__navigation-menu {
            display: inline-block;
            border: 0 none;
            white-space: normal;
            cursor: default;

            &--active.header-section-block__navigation-menu--level1 {
                background: unset;
            }

            &--level1 {
                margin: 26px 0;
                white-space: nowrap;

                .header-section-block__navigation-menu-label {
                    text-decoration: none;
                    cursor: pointer;
                    padding: 0;

                    &:hover {
                        color: $clear-blue;
                    }
                }

                &-parent {
                    .header-section-block__navigation-menu-label {
                        color: $clear-blue;
                    }
                }
            }
        }

        &__navigation-mega-menu-container {
            background: $white;
            position: absolute;
            top: 78px;
            left: 0;
            z-index: $zindex-main-nav-3;
            width: 100%;
            padding-left: 197px;
        }

        &__burger-links {
            display: none;
        }

        &__links {
            margin-right: 0;

            &:hover {
                color: $clear-blue;
            }
        }

        &__links-burger-menu-link {
            display: none;
        }
    }
}


.header-popover {
    &__item {
        top: 0;
    }

    &__button-close-container {
        background: $white;
        padding: 14px 16px 11px;
        text-align: right;

        .header-popover__button-close {
            cursor: pointer;

            > svg {
                fill: $gray-10;
                width: 26px;
                height: 26px;
            }

            &:hover {
                > svg {
                    fill: $clear-blue;
                }
            }
        }
    }

    &__description {
        width: 100%;
    }

    &__button {
        cursor: pointer;

        &:hover {
            > svg {
                fill: $clear-blue;
            }
        }
    }

    &__arrow {
        height: 1.5625rem;
        width: 37px;
        display: none;
    }

    &__item {
        display: none;
        width: 100%;
        height: 100%;
        flex-direction: column;
        padding: 0;
        position: fixed;
        right: 0;
        left: 0;
        transform: unset;
        border-bottom: 0;
        z-index: $zindex-main-nav-1;
        background: rgba(0, 0, 0, 0.7);
        box-shadow: unset;
    }

    &__description {
        display: flex;
        flex-direction: column;
        padding: 0;
        position: absolute;
        top: 0;
        right: 0;
        z-index: $zindex-main-nav-3;
        overflow-y: auto !important;
        background: $white;
        height: 100%;
    }

    &--active {
        .header-popover__item {
            display: block;
        }
    }

    .header-section-block__links-menu-link-item {
        display: flex;
        color: $gray-10;
        font-size: 12px;
        line-height: 18px;
        text-decoration: none;
        margin: 12px 0;
        text-transform: capitalize;

        &--active,
        &:hover {
            color: $clear-blue;
        }

        &--header {
            font-weight: bold;

            &:hover {
                color: $gray-10;
            }
        }

        &--logout {
            background: transparent;
            border: 0 none;
            cursor: pointer;
            font-family: 'Open Sans';
            padding: 0;
        }
    }
}
