@import "@milestone-sys/web-design-system/scss/_variables.scss";

/* Spinner */
.content-overlay {
  position: fixed;
  opacity: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 15000;
  cursor: pointer;
  background: $rgba-6;
  transition: all 0.25s cubic-bezier(0.6, 0, 0.4, 1);
  text-align: center;
  /* spinner positioning */
  .loader-overlay {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 50%;
    top: auto;

    &__msds-loader {
      width: 40px;
      height: 40px;
      fill: $clear-blue;
      -webkit-animation: animationFrames 1.5s cubic-bezier(0.165, 0.84, 0.44, 1)
        infinite;
      animation: animationFrames 1.5s cubic-bezier(0.165, 0.84, 0.44, 1)
        infinite;
    }
  }
}
